import React from 'react'

const Label = ({ labelTitle, name, type, onChange, value, required, className }) => {
  return (
    <div className={className}>
      <label htmlFor={name}>
        <h3>{labelTitle}</h3>
        <div>
          <input
            className="inline-block border border-primarygray rounded h-10 w-full mt-1"
            type={type}
            name={name}
            required={required}
            onChange={onChange}
            value={value}
          />
        </div>
      </label>
    </div>
  )
}

export default Label
