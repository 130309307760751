import React from 'react'
import Layout from '../components/shared/components/Layout'
import ContactForm from '../components/ContactPage/ContactForm'
import SEO from '../components/Seo'

const contact = () => {
  return (
    <Layout>
      <SEO title="Contact Page" />
      <div className="container mx-auto">
        <div className="flex flex-col w-1/2 mt-16 content-start px-8">
          <h1>Request an Invite</h1>
          <p className="mt-6 content">
            We’re looking for sites with:
            <ul>
              <li>relatively little Javascript (easier to implement)</li>
              <li>
                more than 10K page visits per month (for intelligently predicting user behavior)
              </li>
            </ul>
          </p>

          <ContactForm className="mt-6" />
        </div>
      </div>
    </Layout>
  )
}

export default contact
