import React, { useState } from 'react'
import Recaptcha from 'react-google-recaptcha'

import useFormHooks from './CustomHooks/FormHooks'
import Label from './components/Label'
import Button from '../../shared/components/Button'
import '../../../css/content.css'

const ContactForm = ({ className }) => {
  const [validRecaptcha, setValidRecaptcha] = useState(false)
  const [successSubmit, setSuccessSubmit] = useState(-1)
  const [showForm, setShowForm] = useState(true)

  const successMessage = success => {
    if (success === -1) return null
    if (success === 1)
      return (
        <h3 className="mt-6 text-primary font-bold">Success! Thank you for your submission!</h3>
      )
    if (success === 0)
      return (
        <h3 className="mt-6 text-tertiary font-bold">Something went wrong, please try again!</h3>
      )
    return null
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&')
  }
  const { inputs, handleInputChange, handleSubmit, clearInputs } = useFormHooks(() => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...inputs })
    })
      .then(() => {
        clearInputs()
        setShowForm(false)
        setSuccessSubmit(1)
      })
      .catch(() => {
        setShowForm(true)
        setSuccessSubmit(0)
      })
  })

  return (
    <section className={className}>
      {showForm && (
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <Label
            labelTitle="Name"
            name="fullName"
            type="text"
            required
            onChange={handleInputChange}
            value={inputs.fullName || ''}
            className="my-6"
          />

          <Label
            labelTitle="Email"
            name="email"
            type="email"
            required
            onChange={handleInputChange}
            value={inputs.email || ''}
            className="my-6"
          />

          <Label
            labelTitle="Website url"
            name="website"
            type="text"
            required
            onChange={handleInputChange}
            value={inputs.website || ''}
            className="my-6"
          />

          {/* <Label
            labelTitle="Subject"
            name="subject"
            type="text"
            required
            onChange={handleInputChange}
            value={inputs.subject || ''}
            className="my-6"
          /> */}

          <div className="my-6">
            <label htmlFor="message">
              <h3>Why web performance matters to you?</h3>
              <div>
                <textarea
                  rows="4"
                  className="border border-primarygray rounded w-full"
                  name="message"
                  required
                  onChange={handleInputChange}
                  value={inputs.message || ''}
                />
              </div>
            </label>
          </div>

          <Recaptcha
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            onChange={() => setValidRecaptcha(true)}
          />
          <Button type="submit" className="my-6" disabled={!validRecaptcha}>
            Request an Invite
          </Button>
        </form>
      )}
      {successMessage(successSubmit)}
    </section>
  )
}

export default ContactForm
