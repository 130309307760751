import { useState } from 'react'

const useFormHooks = callback => {
  const [inputs, setInputs] = useState({})

  const handleSubmit = event => {
    if (event) {
      event.preventDefault()
      callback()
    }
  }

  const handleInputChange = event => {
    event.persist()
    setInputs(input => ({ ...input, [event.target.name]: event.target.value }))
  }

  const clearInputs = () => {
    Object.keys(inputs).forEach(field => setInputs(input => ({ ...input, [field]: '' })))
  }

  return {
    handleSubmit,
    handleInputChange,
    inputs,
    clearInputs
  }
}

export default useFormHooks
